// middleware/replaceCoursesMiddleware.js

// export default function ({ route, redirect, store, query }) {
//   // Check if the route path contains 'courses'
//   if (route.path.includes("courses")) {
//     if (query) {
//       store.commit("SET_QUERY", query);
//     }
//     // Replace 'courses' with 'materials'
//     if (route.path.split("/").length > 2) {
//       const newPath = route.path.replace(
//         route.path,
//         `/materials/${route.path.split("/")[2]}`
//       );
//       return redirect(301, { path: newPath, query });
//     } else {
//       const newPath = route.path.replace(route.path, `/materials`);
//       // Redirect to the new path
//       return redirect(301, { path: newPath, query });
//     }
//   }
// }

// eslint-disable-next-line no-undef
import { useHomeStore } from '@/stores/HomeStore';

export default defineNuxtRouteMiddleware((to, from, { redirect }) => {
  const homeStore = useHomeStore();    
  if (to.path.includes('courses')) {
    if (to.query) {
      homeStore.setRouteQuery(to.query);
    }
    const newPath = to.path.replace('courses', 'materials');
    return redirect({ path: newPath, query: to.query }, 301);
  }
});
